<template>
  <v-container fluid class="mt-5">
    <v-row class="mb-8">
      <v-col>
        <h2>Dashboard Nível de Tensão</h2>
      </v-col>
    </v-row>

    <v-tabs v-model="tab" fixed-tabs color="primary">
      <v-tab>
        Global
      </v-tab>
      <v-tab>
        Geral
      </v-tab>
      <v-tab>
        Municípios
      </v-tab>
      <!-- <v-tab>
        Medidores Amostrais
      </v-tab> -->
    </v-tabs>

    <v-card class="my-0 parametros-v-card">
      <v-card-title>
        <h4>Parâmetros</h4>
      </v-card-title>
      <v-card-text>
        <v-row class="mx-auto mt-2" justify="start">
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência de"
              rules="min:7"
              :minMonth="'2000-01'"
              :selectedMonth="competenciaDe"
              @monthSelected="setCompetenciaDe"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <input-month
              label="Competência Até"
              rules="min:7"
              :minMonth="'2000-01'"
              :selectedMonth="competenciaAte"
              @monthSelected="setCompetenciaAte"
            />
          </v-col>

          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 0 && tab <= 1) || tab == 2"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaInspecoes"
              v-model="inspecoesSelecionadas"
              multiple
              small-chips
              label="Resultado da Inspeção"
              clearable
              @change="inspecoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 2 && tab <= 1) || tab == 2"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaMedicoes"
              v-model="medicoesSelecionadas"
              multiple
              small-chips
              label="Resultado da Medição"
              clearable
              @change="medicoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 2 && tab <= 1) || tab == 2"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaOrigemMedicoes"
              v-model="origemMedicoesSelecionadas"
              multiple
              small-chips
              label="Origem da Medição"
              clearable
              @change="origemMedicoesSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
          <v-col
            class="pt-0"
            cols="12"
            sm="6"
            md="4"
            v-if="(subTab == 2 && tab <= 1) || tab == 2"
          >
            <v-autocomplete
              class="pl-4"
              :items="listaTensaoReferencia"
              v-model="tensaoReferenciaSelecionadas"
              multiple
              small-chips
              label="Tensão de Referência"
              clearable
              @change="tensaoReferenciaSelecionadosIsDirty = true"
              @blur="atualizaDados()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-tabs-items
      v-model="tab"
      style="background-color: transparent !important;"
    >
      <v-tab-item>
        <dashboard-nivel-tensao-visao-global
          ref="visaoGlobal"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :subTab.sync="subTab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <v-tab-item>
        <dashboard-nivel-tensao-visao-geral
          ref="visaoGeral"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :subTab.sync="subTab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>
      <v-tab-item>
        <dashboard-nivel-tensao-visao-municipios
          ref="visaoMunicipios"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :inspecoesSelecionadas="inspecoesSelecionadas"
          :medicoesSelecionadas="medicoesSelecionadas"
          :origemMedicoesSelecionadas="origemMedicoesSelecionadas"
          :tensaoReferenciaSelecionadas="tensaoReferenciaSelecionadas"
          :categories="categories"
          :activatedTab="tab"
          :traducaoIndicadores="traducaoIndicadores"
        />
      </v-tab-item>

      <!-- <v-tab-item>
        <dashboard-nivel-tensao-visao-medidores-amostrais
          ref="visaoMedidoresAmostrais"
          :competenciaDe="competenciaDe"
          :competenciaAte="competenciaAte"
          :activatedTab="tab"
        />
      </v-tab-item> -->
    </v-tabs-items>

    <v-overlay :value="loadingDados" :opacity="0.85">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </v-container>
</template>

<script>
  import { generateMonthlyDateRangeArray } from "@/utils/dateUtils.js";

  import Highcharts from "highcharts";

  import HighchartsNoData from "highcharts/modules/no-data-to-display";
  import HighchartsBoost from "highcharts/modules/boost";
  import HighchartsExporting from "highcharts/modules/exporting";
  import HighchartsExportData from "highcharts/modules/export-data";
  import HighchartsDrilldown from "highcharts/modules/drilldown";
  import HighchartsData from "highcharts/modules/data";
  import HighchartsAccessibility from "highcharts/modules/accessibility";
  HighchartsNoData(Highcharts);
  HighchartsBoost(Highcharts);
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  HighchartsDrilldown(Highcharts);
  HighchartsData(Highcharts);
  HighchartsAccessibility(Highcharts);

  export default {
    name: "DashboardNivelTensao",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      DashboardNivelTensaoVisaoGlobal: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoGlobal.vue"
        ),
      DashboardNivelTensaoVisaoGeral: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoGeral.vue"
        ),
      DashboardNivelTensaoVisaoMunicipios: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoMunicipios.vue"
        ),
      DashboardNivelTensaoVisaoMedidoresAmostrais: () =>
        import(
          "@/components/dashboardNivelTensao/DashboardNivelTensaoVisaoMedidoresAmostrais.vue"
        ),
    },
    data: () => ({
      loadingDados: false,
      tab: 0,
      subTab: 0,
      competenciaDe: "2024-01",
      competenciaAte: "2024-06",
      listaInspecoes: ["Não adequada", "Regularizada", "Adequada"],
      inspecoesSelecionadas: [],
      inspecoesSelecionadosIsDirty: false,
      listaMedicoes: ["NC", "CO"],
      medicoesSelecionadas: [],
      medicoesSelecionadosIsDirty: false,
      listaOrigemMedicoes: ["AMS", "PER", "EVE"],
      origemMedicoesSelecionadas: [],
      origemMedicoesSelecionadosIsDirty: false,
      listaTensaoReferencia: [380, 13800, 220],
      tensaoReferenciaSelecionadas: [],
      tensaoReferenciaSelecionadosIsDirty: false,

      traducaoIndicadores: {
        // Reclamação
        qrt: "Quantidade Relativa de Reclamações",
        prp: "Percentual de Reclamações Procedentes",
        "QTD Reclamações Totais": "Quantidade Reclamações Totais",
        "QTD Reclamações Procedentes": "Quantidade Reclamações Procedentes",
        // Qualidade Serviços
        irnt: "Índice de Regularização do Nível de Tensão",
        psfpg:
          "Percentual De Serviços Comerciais Fora Do Prazo Por Grupo De Serviço",
        "psfpt-crp":
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (CRP)",
        "psfpt-cri":
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (CRI)",
        "psfpt-rmp":
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (RMP)",
        "psfpt-rmc":
          "Percentual De Serviços Comerciais Fora Do Prazo Por Tipo De Serviço (RMC)",
        "Total Compensação": "Total Compensação",
        // Indicadores Técnicos
        icnt: "Índice de Conformidade do Nível de Tensão",
        plna: "Percentual de Leituras de Tensão não Adequadas",
        drpe:
          "Duração Relativa de Transgressão para Tensão Precária Equivalente",
        drce:
          "Duração Relativa de Transgressão para Tensão Crítica Equivalente",
      },
    }),
    computed: {
      categories() {
        const startDate = this.competenciaDe;
        const endDate = this.competenciaAte;

        return generateMonthlyDateRangeArray(startDate, endDate, "YYYY/MM");
      },
    },
    mounted() {},
    methods: {
      setCompetenciaDe(data) {
        this.competenciaDe = data;
      },
      setCompetenciaAte(data) {
        this.competenciaAte = data;
      },
      atualizaDados() {
        if (
          !this.inspecoesSelecionadosIsDirty &&
          !this.medicoesSelecionadosIsDirty &&
          !this.origemMedicoesSelecionadosIsDirty && 
          !this.tensaoReferenciaSelecionadosIsDirty
        ) {
          return;
        }

        this.$refs?.visaoGlobal?.getDados();
        this.$refs?.visaoGeral?.getDados();
        this.$refs?.visaoMunicipios?.getDados();
        this.$refs?.visaoMedidoresAmostrais?.getDados();

        this.inspecoesSelecionadosIsDirty = false;
        this.medicoesSelecionadosIsDirty = false;
        this.origemMedicoesSelecionadosIsDirty = false;
        this.tensaoReferenciaSelecionadosIsDirty = false;
      },
    },
    watch: {},
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
</style>
